<template>
  <div>
    <div class="backIcon" @click="backChange">〈 返回</div>
    <a-form-model :model="dataForm" ref="dataForm" layout="inline" :rules="rules">
      <div class="dataForm_title">
        请填写下列基本信息
        <span class="red">(*号必填)</span>
      </div>
      <a-form-model-item label="孩子姓名" prop="child_name">
        <a-input
          type="text"
          v-model="dataForm.child_name "
          placeholder="请输入孩子姓名"
          :maxLength='4'
          :disabled="disabled"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item label="出生日期" prop="birth">
        <vb-date-picker
          v-model="dataForm.birth"
          placeholder="请输入出生日期"
          valueFormat="YYYY-MM-DD"
          :disabled="disabled"
          disDate='newDate'
          @change="ageChange(dataForm.birth)"
        />
      </a-form-model-item>
      <a-form-model-item label="评估日期 " prop="age">
        <vb-date-picker
          v-model="dataForm.assess_time"
          placeholder="请选择评估日期"
          valueFormat="YYYY-MM-DD"
           disDate='newDate'
        />
      </a-form-model-item>
      <a-form-model-item label="家长姓名 " prop="parent_name">
        <a-input
          type="text"
          v-model="dataForm.parent_name "
          placeholder="请输入家长姓名"
          :disabled="disabled"
          :maxLength='4'
        ></a-input>
      </a-form-model-item>
      <a-form-model-item label="测评老师  " prop="tea_name">
        <a-input
          type="text"
          v-model="dataForm.tea_name "
          placeholder="请输入测评老师"
          :maxLength='4'
        ></a-input>
      </a-form-model-item>
      <a-form-model-item label="联系方式  " prop="mobile">
        <a-input type="text" v-model="dataForm.mobile " placeholder="请输入联系方式" :disabled="disabled"></a-input>
      </a-form-model-item>
    </a-form-model>
    <div class="evaluating_box">
      <vb-stage v-model='second'>
        <template v-slot:title>里程碑评估计分表，根据测评孩子的情况进行填充</template>
      </vb-stage>
      <vbEvaluationRecord v-model="tableForm" :num="num" :second='second' />
      <div class="ant_btn">
        <a-button @click="handleSubmit">生成报告</a-button>
      </div>
    </div>
  </div>
</template>
<script>
import vbStage from '@/components/content/stage'
import vbEvaluationRecord from '@/components/content/evaluationRecord'
import { getRecord, getRecodeDetail } from '@/api/record'
export default {
  name: 'evaluating',
  components: { vbStage, vbEvaluationRecord },
  data () {
    return {
      num: 1,
      pharseSorts: [{ require: 1, nominate: 2 }, {}, {}],
      tableForm: [
        [
          {
            name_id: 'require',
            choose: 0,
            submitInfo: '000000',
            name: '提要求',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'nominate',
            submitInfo: '000000',
            choose: 0,
            name: '命名',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'reaction',
            choose: 0,
            submitInfo: '000000',
            name: '听者反应',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'pair',
            choose: 0,
            submitInfo: '000000',
            name: '视觉配对',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'game',
            choose: 0,
            submitInfo: '000000',
            name: '独立游戏',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'contact',
            choose: 0,
            submitInfo: '000000',
            name: '社交',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'imitate',
            choose: 0,
            submitInfo: '000000',
            name: '模仿',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'echolalia',
            choose: 0,
            submitInfo: '000000',
            name: '仿说',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'sound',
            choose: 0,
            submitInfo: '000000',
            name: '发音',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          }
        ],
        [
          {
            name_id: 'require',
            choose: 0,
            submitInfo: '000000',
            name: '提要求',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'nominate',
            submitInfo: '000000',
            choose: 0,
            name: '命名',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'reaction',
            choose: 0,
            submitInfo: '000000',
            name: '听者反应',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'pair',
            choose: 0,
            submitInfo: '000000',
            name: '视觉配对',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'game',
            choose: 0,
            submitInfo: '000000',
            name: '独立游戏',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'contact',
            choose: 0,
            submitInfo: '000000',
            name: '社交',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'imitate',
            choose: 0,
            submitInfo: '000000',
            name: '模仿',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'echolalia',
            choose: 0,
            submitInfo: '000000',
            name: '仿说',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'lrffc',
            choose: 0,
            submitInfo: '000000',
            name: 'LRFFC',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'talk',
            choose: 0,
            submitInfo: '000000',
            name: '对话',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'team',
            choose: 0,
            submitInfo: '000000',
            name: '集体技能',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'structure',
            choose: 0,
            submitInfo: '000000',
            name: '语言结构',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          }
        ],
        [
          {
            name_id: 'require',
            choose: 0,
            name: '提要求',
            submitInfo: '000000',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'nominate',
            choose: 0,
            submitInfo: '000000',
            name: '命名',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'reaction',
            choose: 0,
            submitInfo: '000000',
            name: '听者反应',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'pair',
            choose: 0,
            submitInfo: '000000',
            name: '视觉配对',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'game',
            choose: 0,
            submitInfo: '000000',
            name: '独立游戏',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'contact',
            choose: 0,
            submitInfo: '000000',
            name: '社交',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'read',
            choose: 0,
            submitInfo: '000000',
            name: '阅读',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'write',
            choose: 0,
            submitInfo: '000000',
            name: '书写',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'lrffc',
            choose: 0,
            submitInfo: '000000',
            name: 'LRFFC',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'talk',
            choose: 0,
            submitInfo: '000000',
            name: '对话',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'team',
            choose: 0,
            submitInfo: '000000',
            name: '集体技能',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'structure',
            choose: 0,
            submitInfo: '000000',
            name: '语言结构',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          },
          {
            name_id: 'mathema',
            choose: 0,
            submitInfo: '000000',
            name: '数学',
            option: [
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' },
              { key: 0, value: 0, color: '' }
            ]
          }

        ]
      ],
      dataForm: {
        child_name: '',
        parent_name: '',
        birth: null,
        age: '',
        mobile: '',
        tea_name: ''
      },
      second: 4,
      disabled: false,
      mobile: this.$route.query.mobile,
      id: this.$route.query.id,
      isShow: false
    }
  },
  mounted () {
    if (this.mobile) {
      this.disabled = true
      this.getInfo()
    }
  },
  methods: {
    updateKey () {
      // debugger
      const obj = {}
      for (let i = 0; i <= 2; i++) {
        const stage = this.tableForm[i]
        stage.forEach((item, key, array) => {
          obj[item.name_id] = obj[item.name_id] || []
          obj[item.name_id].push(...item.option)
        })
      }
      for (var key in obj) {
        this.updateOption(obj[key])
      }
    },
    updateOption (options) {
      // 从上面往下面找当前项目这次评估的第一个格子
      let index = options.length - 1
      let selectedFlag = false
      while (index > 0) {
        const item = options[index]
        if (item.key === this.num && item.value !== 0) {
          selectedFlag = true
          break
        } else {
          index--
        }
      }
      if (selectedFlag) {
        // 将当次选择的以下的key为0的设置为当次评估记录
        for (let i = index; i >= 0; i--) {
          const item = options[i]
          if (item.key === 0) {
            item.key = this.num
          }
        }
      }
    },
    async getInfo () {
      const res = await getRecodeDetail(this.mobile)
      if (res.code !== '1') {
        return this.$message.error(res.msg)
      }
      this.dataForm = {
        child_name: res.data.child_name,
        parent_name: res.data.parent_name,
        birth: Number(res.data.birth),
        age: this.birth(res.data.birth),
        mobile: res.data.mobile,
        tea_name: res.data.tea_name,
        assess_time: res.data.assess_time
      }
      this.tableForm = [
        this.transformQuery(res.data.one_stage),
        this.transformQuery(res.data.two_stage),
        this.transformQuery(res.data.three_stage)
      ]
      this.num = Number(res.data.num) + 1
      this.second = Number(res.data.second)
    },
    transformQuery (stage) {
      const target = []
      stage.forEach((item, key, array) => {
        target.push(item[0])
      })
      return target
    },
    transform (stage) {
      const target = []
      stage.forEach((item, key, array) => {
        target.push([item])
      })
      return JSON.stringify(target)
    },
    handleSubmit () {
      this.$refs.dataForm.validate(async valid => {
        if (!valid) {
          return this.$message.info('请填必填项！')
        }
        this.updateKey()
        console.log(this.second)
        const data = {
          ...this.dataForm,
          num: this.num,
          id: this.id,
          second: this.second,
          one_stage: this.transform(this.tableForm[0]),
          two_stage: this.transform(this.tableForm[1]),
          three_stage: this.transform(this.tableForm[2])
        }
        const res = await getRecord(data)
        if (res.code !== '1') {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.$router.push({ path: '/evaluatingDetail', query: { curIndex: 1, mobile: this.dataForm.mobile } })
      })
    },
    backChange () {
      this.$router.go(-1)
    },
    ageChange (birth) {
      this.dataForm.age = this.birth(birth)
    },
    birth (birth) {
      if (!birth) {
        return ''
      }
      const birthDate = birth * 1000
      const birthYear = new Date(birthDate).getFullYear()
      const birthMonth = new Date(birthDate).getMonth() + 1
      const newYear = new Date().getFullYear()
      const newMonth = new Date().getMonth() + 1
      let time
      if (newMonth < birthMonth) {
        time = parseInt(newYear - birthYear - 1) + '岁' + parseInt(12 + (newMonth - birthMonth)) + '个月'
      } else {
        time = parseInt(newYear - birthYear) + '岁' + parseInt(newMonth - birthMonth) + '个月'
      }
      return time
    }
  },
  computed: {
    rules () {
      // 验证手机号的规则
      const checkMobile = (ruls, value, cb) => {
      // 验证手机的正则表达式
        const regMobile = /^1[^012][0-9]{9}$/
        if (regMobile.test(value)) {
          return cb()
        } else {
          cb(new Error('请输入合法的手机号'))
        }
      }
      return {
        child_name: [{ required: true, message: '请填写孩子姓名!', trigger: 'blur' }],
        birth: [{ required: true, message: '请填写出生日期!', trigger: 'change' }],
        age: [
          { required: true, message: '请输入年龄', trigger: 'blur' }
        ],
        parent_name: [{ required: true, message: '请填写家长姓名!', trigger: 'blur' }],
        tea_name: [{ required: true, message: '请填写测评老师!', trigger: 'blur' }],
        mobile: [
          { required: true, message: '请填写联系方式!', trigger: 'blur' },
          { validator: checkMobile }
        ]
      }
    }
  }
}
</script>
<style lang="less" scoped>
.ant-input,
.ant-calendar-picker {
  width: 264px;
  height: 40px;
}
.ant-form-item {
  width: 384px;
  margin-bottom: 30px;
}

.dataForm_title {
  margin-bottom: 43px; font-size: 20px;
  span {
    color: var(--color-tint);
  }
}
::v-deep .ant-calendar-picker-input {
  height: 40px;
}

.ant_btn ::v-deep .ant-btn {
  background: var(--color-tint);
  color: #fff;
  border: none;
  width: 190px;
  height: 44px;
  margin: 0 auto;
  display: block;
}
.ant_btn {
  padding-bottom: 85px;
}
.backIcon{cursor: pointer; font-size: 18px;padding-bottom: 36px; display: inline-block;}
</style>
